<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                title="Note List"
                btnTitle="Add note"
                @onClickAddNewButton="onClickAdd"
            />
        </div>
       
        <div class="my-2 px-2">

            <div class="row">
                
                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                    <label for="colFormLabel" class="col-form-label">Search</label>
                    <div class="input-group input-group-merge w-100">

                        <span class="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </span>

                        <input 
                            type="text" class="form-control"
                            placeholder="Search by Note name" aria-label="Search..."
                            aria-describedby="chat-search"
                            v-model="params.searchTxt"
                        />

                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                    <label for="colFormLabel" class="col-form-label">Types: </label>
                    <v-select
                        placeholder="Select type"
                        :options="noteTypes" v-model="params.note_type"
                        :reduce="name => name.value"
                        label="name"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-2 col-lg-2">
                    <label for="colFormLabel" class="col-form-label">Status: </label>
                    <v-select 
                        placeholder="Select Status" class="w-100" 
                        :options="noteStatus" label="name"
                        v-model="params.status"
                        :reduce="(name) => name.value" 
                    />
                </div>

                <div class="col-2 d-flex align-items-end mt-2">
                    <button
                        @click="onSearch"
                        class="btn btn-primary btn-block waves-effect waves-float waves-light"
                    >
                    Go
                    </button>
                </div>

            </div>


        </div>

        <div class="col-12 px-2 mt-2 mb-2">
            <ListTable 
                v-if="noteList.data" 
                :tableItems="noteList"
                @onClickToEdit="onClickToEdit"
                :noteTypesInKeyValue="noteTypesInKeyValue"
            />
        </div>
        
        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <AddOrEditNoteModal 
            v-if="$store.state.isModalOpen"
            :noteStatus="noteStatus"
            :noteTypes="noteTypes"
            :editData="editData"
            @onSubmit="onSubmit"
        />

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import ListTable from '@/components/molecule/company/hospital/note-master/NoteMasterTable.vue';
    import AddOrEditNoteModal from '@/components/molecule/company/hospital/note-master/AddOrEditNoteModal.vue';
    import handleHospital from '@/services/modules/hospital'
    import Loader from '@/components/atom/LoaderComponent'
    import Pagination from '@/components/atom/Pagination'

    import { reactive, ref, inject, onMounted, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    const $router = useRouter();
    const $route = useRoute();
    const $store = useStore();

    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const loading = ref(false)
    const offset = ref(20);

    const params = reactive({
        searchTxt: '',
        status: null,
        note_type: null
    });

    const page = computed(() => $route.query.page);

    //methods
    const setPaginationData = (data) => {
        $store.commit('setPaginationData', data)
    };
    const resetPagination = () => {
        $store.commit('resetPagination')
    };

    const {
        fetchNoteMasterList,
        fetchNoteTypes,
    } = handleHospital()

    const editData = ref(null);    

    const noteList = ref([]);
    const noteTypesInKeyValue = ref({});

    const noteStatus = reactive([
        {
            value: 'active',
            name: 'Active',
        },
        {
            value: 'inactive',
            name: 'Inactive'
        }
    ])

    const noteTypes = reactive([])

    const onClickToEdit = (item) => {
        editData.value = item;
        openModal();
    }

    const onSearch = () => {
        getNoteList();
    }

    const setPagination = (data) => {
        setPaginationData({
            records: data.total,
            perPage: data.per_page,
            currentPage: data.current_page,
        })
    }

    function onPageChange(page) {
        let routeQuery = Object.assign({}, $route.query)
        routeQuery.page = page
        $router.push({path: $route.path, query: routeQuery})
        setTimeout(() => {
            getNoteList();
        }, 100)
    }

    function getQuery() {
        let query = `?company_id=${$route.params.companyId}`;
        if(params.searchTxt) query += `&searchTxt=${params.searchTxt}`;
        if(params.status) query += `&status=${params.status}`;
        if(params.note_type) query += `&note_type=${params.note_type}`;
        query += '&offset=' + offset.value
        if ($route.query.page) query += '&page=' + $route.query.page
        return query;
    }

    const getNoteTypes = async () => {
        noteTypes.length = 0;
        noteTypesInKeyValue.value = {};
        loading.value = true
        let query = getQuery();
        await fetchNoteTypes(query).then(res => {
            loading.value = false
            if (!res.status){
                return showError(res.message)
            } 
            noteTypes.push(...res.data);
            noteTypesInKeyValue.value = res.noteTypes;
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const getNoteList = async () => {
        loading.value = true
        let query = getQuery();
        await fetchNoteMasterList(query).then(res => {
            loading.value = false
            if (!res.status){
                $store.commit("resetPagination")
                return showError(res.message)
            }
            noteList.value = res.data;
            setPagination(res.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const onSubmit = () => {
        getNoteList();
        $store.state.isModalOpen = false;
    }

    const openModal = () => {
        setTimeout(() => {
            $store.state.isModalOpen = true;
        }, 5)
    }

    const onClickAdd = () => {
        editData.value = null;
        openModal();
    }

    onMounted(async () => {
        Promise.all([
            await getNoteList(),
            await getNoteTypes(),
        ])
    })
</script>

<style>

</style>