<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    						
                    <tr>
                        <th class="pl-10px">Type</th>
                        <th>Note name</th>
                        <th>Created by</th>
                        <th>Code</th>
                        <th>Short desc</th>
                        <th>Status</th>
                        <th>Default</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
              
                <tbody>
                    <tr v-for="item in tableItems.data" :key="item.id">

                        <td class="pl-10px">
                            {{ noteTypesInKeyValue[item.type] }}
                        </td>

                        <td>
                            {{ item.name }}
                        </td>

                        <td>
                            {{ item.created_by ? item.created_by.name: '' }}
                        </td>

                        <td>
                            {{ item.code }}
                        </td>

                        <td>
                            {{ item.short_desc }}
                        </td>

                        <td>
                            <span :class="`badge bg-${statusClassObj[item.status]}`">{{ toCapitalizeFirst(item.status) }}</span>
                        </td>

                        <td>
                            {{ item.is_default ? 'Yes' : 'No' }}
                        </td>

                        <td>
                            <div class="action">
                                <button @click="editNote(item)" class="btn btn-outline-primary btn-sm print-btn">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.data.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { reactive } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import { toCapitalizeFirst } from "@/services/utils/global";

    const $route = useRoute();
    const $router = useRouter();
    const statusClassObj = reactive({
        'active' : 'success',
        'inactive': 'danger'
    })
    const props = defineProps({
        tableItems: {
            type: Object,
        },
        onClickToEdit: Function,
        noteTypesInKeyValue: Object,
    })
    const $emit = defineEmits(['onClickToEdit'])

    const editNote = (item) => {
        $emit('onClickToEdit', item)
    }
</script>

<style scoped>

.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

</style>