<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ `${editData ? 'Edit' : 'Add'} Note` }}</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">
                
                <div class="modal-body">
                    
                    <div class="row gy-2 px-2">

                        <div class="d-block">
                            <div class="form-check float-end">
                            <input
                                type="checkbox"
                                v-model="formData.is_default"
                                class="form-check-input"
                            >
                            Is Default
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="form-label"><span class="required">*</span> Select type</label>
                            <v-select
                                placeholder="Select type"
                                :options="noteTypes" v-model="formData.type"
                                :reduce="name => name.value"
                                label="name"
                            />
                            <span v-if="formErrors.type" class="invalid-feedback d-block qcont">{{formErrors.type}}</span>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="form-label"><span class="required">*</span> Note name</label>
                            <div class="input-group">
                                <input
                                    type="text"
                                    name="name" placeholder="Enter note name"
                                    v-model.trim="formData.name"
                                    class="form-control"
                                />
                                <span v-if="formErrors.name" class="invalid-feedback d-block qcont">{{formErrors.name}}</span>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="form-label">Code</label>
                            <div class="input-group">
                                <input
                                    type="text"
                                    name="code" placeholder="Enter code"
                                    v-model.trim="formData.code"
                                    class="form-control"
                                />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="form-label">Short desc</label>
                            <div class="input-group">
                                <textarea
                                    rows="2" name="short_desc" 
                                    placeholder="Enter short description"
                                    v-model.trim="formData.short_desc"
                                    class="form-control"
                                ></textarea>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="form-label">Full desc</label>
                            <div class="input-group">
                                <textarea
                                    rows="3" name="full_desc" 
                                    placeholder="Enter full description"
                                    v-model.trim="formData.full_desc"
                                    class="form-control"
                                ></textarea>
                            </div>
                        </div>

                        <div v-show="editData" class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label class="form-label"><span class="required">*</span> Select status</label>
                            <v-select
                                placeholder="Select status"
                                :options="noteStatus" v-model="formData.status"
                                :reduce="name => name.value"
                                label="name"
                            />
                            <span v-if="formErrors.status" class="invalid-feedback d-block qcont">{{formErrors.status}}</span>
                        </div>

                        <div class="col-12 text-center">
                            <hr>
                            <button :disabled="loading" @click="submit" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <span v-if="!loading">
                                    <i class="fas fa-plus"></i>
                                </span>
                                
                                {{ loading ? 'Please wait...' : (editData ? 'Update' : 'Save') }}
                            </button>
                            <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </div>
                
                </div>
               
            </div>
        </div>
    </div>
</template>

<script setup>
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import handleHospital from '@/services/modules/hospital'
import { inject, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
const store = useStore();
const route = useRoute();
const modalRef = ref(null);
const props = defineProps([
    'note', 
    'onSubmit',
    'noteStatus',
    'noteTypes',
    'editData',
])
const $emit = defineEmits(['onSubmit', 'onClose'])
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {
    storeNote,
    updateNote
} = handleHospital();

const formData = reactive({
    company_id: route.params.companyId,
    type: null,
    status: 'active',
    name: '',
    code: '',
    short_desc: '',
    full_desc: '',
    is_default: false,
})

const setFormData = (data) => {
    formData.id = data.id;
    formData.type = data.type;
    formData.status = data.status;
    formData.name = data.name;
    formData.code = data.code;
    formData.short_desc = data.short_desc;
    formData.full_desc =  data.full_desc;
    formData.is_default = data.is_default;
}

onMounted(() => {
    if(props.editData){
        setFormData(props.editData);
    }
});

const formErrors = reactive({
      name: '',
      type: '',
      status: '',
})

useDetectOutsideClick(modalRef, () => {
    closeModal();
})

const submit = async () => {
    if(checkValidation()) return;

    try {
        loading.value = true
        let res = props.editData ? await updateNote(formData) : await storeNote(formData);
        loading.value = false
        if (!res.status) {
            showError(res.message);
            return;
        }
        $emit('onSubmit');
        showSuccess(res.message)
  } catch (err) {
    
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!');
      return;
    }
    showError(err.response.message)
  } finally {
    loading.value = false
  }
}

const closeModal = () => {
    store.state.isModalOpen = false;
}

const checkValidation = () => {
    let flag = false;
    if(!formData.name){
        formErrors.name = 'Note name is required.'
        flag = true;
    }
    if(!formData.type){
        formErrors.type = 'Note type is required.'
        flag = true;
    }
    if(!formData.status){
        formErrors.status = 'Note status is required.'
        flag = true;
    }

    return flag;
}

watch(formData, (newObj, oldObj) => {
    if(newObj.name) formErrors.name= '';
    if(newObj.type) formErrors.type= '';
    if(newObj.status) formErrors.status= '';
});



</script>


<style scoped>
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>